<template>
  <div class="container-fluid home">

    <inicio-component data-aos="opacity-up" class="margen-seccion"></inicio-component>

    <a-que-nos-dedicamos-component data-aos="opacity-up" class="margen-seccion"></a-que-nos-dedicamos-component>

    <valores-component data-aos="opacity-up" class="margen-seccion"></valores-component>

    <nuestros-socios data-aos="opacity-up" class="margen-seccion"></nuestros-socios>

    <ubicaciones-component data-aos="opacity-up" class="margen-seccion"></ubicaciones-component>    

    
    <contacto-component id="contacto" class="margen-seccion" data-aos="opacity-up" ></contacto-component>    
    <div ref="contacto"></div>

    <boton-flotante-component></boton-flotante-component>
    
  </div>
</template>

<script>
import ContactoComponent from '@/components/ContactoComponent.vue'
import BotonFlotanteComponent from '@/components/BotonFlotanteComponent.vue'
import InicioComponent from '@/components/InicioComponent.vue'
import AQueNosDedicamosComponent from '@/components/AQueNosDedicamosComponent.vue'
import ValoresComponent from '@/components/ValoresComponent.vue'
import NuestrosSocios from '@/components/NuestrosSocios.vue'
import UbicacionesComponent from '@/components/UbicacionesComponent.vue'



export default {
  name: 'HomeView',
  components: {
    InicioComponent,    
    AQueNosDedicamosComponent,    
    ValoresComponent,
    ContactoComponent,    
    BotonFlotanteComponent,
    NuestrosSocios,
    UbicacionesComponent,    
  },
  data(){
    return{
      duration: '3000',
      animation: 'opacity-up'
    }
  },
  mounted(){
    //console.log(this.$route.query)
    if(this.$route.query.ref == "contacto"){
      this.$nextTick(()=>{
        this.$nextTick(()=>{
              console.log(this.$refs.contacto)
              this.$smoothScroll({
              scrollTo: this.$refs.contacto,
              duration: 500,
              offset: 0,
            })
          })
      })      
    }
  }  
}
</script>

<style lang="scss" scoped>

.margen-seccion{
  margin: 6em 0;
}
.home{
  padding: 0em 6em;
  //width: 70%;
  margin: 0 auto;

  @media only screen and (max-width: 992px) {
    padding: 0em;
    width: 100%;
  }
}

.titulo{
    // text-center font-size-30 font-weight-bold mb-5
    text-align: center;
    font-size: 30px!important;
    font-weight: bold;
    margin-bottom: 2em;

    .subtitulo{
      color:#929292;
      font-weight: 400;
      font-size: 20px;
    }
  }


</style>