<template>
  <div id="inicio">
    <MenuBar></MenuBar>    
    <router-view/>   
    <pie-pagina-component></pie-pagina-component>    
  </div>
</template>

<script>
import MenuBar from '@/components/MenuBar'
import PiePaginaComponent from './components/PiePaginaComponent.vue'

export default {
  components:{
    MenuBar,
    PiePaginaComponent
  }
}
</script>

<style lang="scss">
  
</style>